var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "inputField" },
    [
      _c("ValidationProvider", {
        class: _vm.classes,
        attrs: {
          tag: _vm.tag,
          vid: _vm.fieldId,
          rules: _vm.rules,
          name: _vm.displayName,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _vm.type === "number"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.inputValue,
                          expression: "inputValue",
                          modifiers: { number: true },
                        },
                      ],
                      staticClass: "input_text inline-block w-full box-border",
                      class: [_vm.disabled ? "bg-light-grey" : ""],
                      attrs: {
                        type: _vm.type,
                        disabled: _vm.disabled,
                        placeholder: _vm.placeholder,
                      },
                      domProps: { value: _vm.inputValue },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.inputValue = _vm._n($event.target.value)
                          },
                          function ($event) {
                            _vm.input(parseInt($event.target.value) || 0)
                          },
                        ],
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    })
                  : _vm.type === "decimal"
                  ? _c("input", {
                      staticClass: "input_text inline-block w-full box-border",
                      class: [_vm.disabled ? "bg-light-grey" : ""],
                      attrs: {
                        type: "text",
                        disabled: _vm.disabled,
                        placeholder: _vm.placeholder,
                      },
                      domProps: { value: _vm.inputDecimalValue },
                      on: {
                        input: function ($event) {
                          return _vm.setNumber($event)
                        },
                      },
                    })
                  : _vm.type === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputValue,
                          expression: "inputValue",
                        },
                      ],
                      staticClass: "input_text inline-block w-full box-border",
                      class: [_vm.disabled ? "bg-light-grey" : ""],
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: _vm.placeholder,
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.inputValue)
                          ? _vm._i(_vm.inputValue, null) > -1
                          : _vm.inputValue,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.inputValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.inputValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.inputValue = $$c
                          }
                        },
                      },
                    })
                  : _vm.type === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputValue,
                          expression: "inputValue",
                        },
                      ],
                      staticClass: "input_text inline-block w-full box-border",
                      class: [_vm.disabled ? "bg-light-grey" : ""],
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: _vm.placeholder,
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.inputValue, null) },
                      on: {
                        change: function ($event) {
                          _vm.inputValue = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputValue,
                          expression: "inputValue",
                        },
                      ],
                      staticClass: "input_text inline-block w-full box-border",
                      class: [_vm.disabled ? "bg-light-grey" : ""],
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: _vm.placeholder,
                        type: _vm.type,
                      },
                      domProps: { value: _vm.inputValue },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputValue = $event.target.value
                        },
                      },
                    }),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: errors.length,
                        expression: "errors.length",
                      },
                    ],
                    staticClass:
                      "text-xxs text-left text-red-600 absolute break-words",
                    style: _vm.errorsWidth,
                  },
                  [_vm._v(" " + _vm._s(errors[0]) + " ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }